import { render, staticRenderFns } from "./Lead.vue?vue&type=template&id=1d015c27&scoped=true&"
import script from "./Lead.vue?vue&type=script&lang=js&"
export * from "./Lead.vue?vue&type=script&lang=js&"
import style0 from "./Lead.vue?vue&type=style&index=0&id=1d015c27&prod&scoped=true&lang=css&"
import style1 from "./Lead.vue?vue&type=style&index=1&id=1d015c27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d015c27",
  null
  
)

export default component.exports