export default Object.freeze({
  reservation_hard: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>
  </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
  <noscript>
    <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
  </noscript>
  <![endif]-->
  <!--[if lte mso 11]>
  <style type="text/css">
    .mj-outlook-group-fix { width:100% !important; }
  </style>
  <![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700" rel="stylesheet" type="text/css">
  <style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
  </style>
  <!--<![endif]-->
  <style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }

      .mj-column-per-50 {
        width: 50% !important;
        max-width: 50%;
      }

      .mj-column-px-220 {
        width: 220px !important;
        max-width: 220px;
      }

      .mj-column-px-260 {
        width: 260px !important;
        max-width: 260px;
      }

      .mj-column-px-300 {
        width: 300px !important;
        max-width: 300px;
      }

      .mj-column-per-17-5 {
        width: 17.5% !important;
        max-width: 17.5%;
      }

      .mj-column-per-28 {
        width: 28% !important;
        max-width: 28%;
      }

      .mj-column-per-19-5 {
        width: 19.5% !important;
        max-width: 19.5%;
      }

      .mj-column-px-120 {
        width: 120px !important;
        max-width: 120px;
      }

      .mj-column-per-33 {
        width: 33% !important;
        max-width: 33%;
      }

      .mj-column-per-40 {
        width: 40% !important;
        max-width: 40%;
      }

      .mj-column-per-27 {
        width: 27% !important;
        max-width: 27%;
      }

      .mj-column-px-110 {
        width: 110px !important;
        max-width: 110px;
      }

      .mj-column-px-5 {
        width: 5px !important;
        max-width: 5px;
      }

      .mj-column-px-105 {
        width: 105px !important;
        max-width: 105px;
      }

      .mj-column-px-130 {
        width: 130px !important;
        max-width: 130px;
      }
    }
  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }

    .moz-text-html .mj-column-per-50 {
      width: 50% !important;
      max-width: 50%;
    }

    .moz-text-html .mj-column-px-220 {
      width: 220px !important;
      max-width: 220px;
    }

    .moz-text-html .mj-column-px-260 {
      width: 260px !important;
      max-width: 260px;
    }

    .moz-text-html .mj-column-px-300 {
      width: 300px !important;
      max-width: 300px;
    }

    .moz-text-html .mj-column-per-17-5 {
      width: 17.5% !important;
      max-width: 17.5%;
    }

    .moz-text-html .mj-column-per-28 {
      width: 28% !important;
      max-width: 28%;
    }

    .moz-text-html .mj-column-per-19-5 {
      width: 19.5% !important;
      max-width: 19.5%;
    }

    .moz-text-html .mj-column-px-120 {
      width: 120px !important;
      max-width: 120px;
    }

    .moz-text-html .mj-column-per-33 {
      width: 33% !important;
      max-width: 33%;
    }

    .moz-text-html .mj-column-per-40 {
      width: 40% !important;
      max-width: 40%;
    }

    .moz-text-html .mj-column-per-27 {
      width: 27% !important;
      max-width: 27%;
    }

    .moz-text-html .mj-column-px-110 {
      width: 110px !important;
      max-width: 110px;
    }

    .moz-text-html .mj-column-px-5 {
      width: 5px !important;
      max-width: 5px;
    }

    .moz-text-html .mj-column-px-105 {
      width: 105px !important;
      max-width: 105px;
    }

    .moz-text-html .mj-column-px-130 {
      width: 130px !important;
      max-width: 130px;
    }
  </style>
  <style type="text/css">
    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }

      td.mj-full-width-mobile {
        width: auto !important;
      }
    }
  </style>
  <style type="text/css">
    .email-notes {
      padding-left: 30px;
    }

    .email-notes li {
      padding: 8px 0;
    }

    a {
      text-decoration: none;
    }

    .bold {
      font-weight: 700;
    }

    .black-bg {
      background-color: black;
    }

    .p10 {
      padding: 10px;
    }

    .w50 {
      width: 50%;
    }

    .mauto {
      margin: 0 auto;
    }

    .right {
      float: right
    }

    .cost-table tr td:last-child {
      text-align: right
    }

    .pt0 {
      padding-top: 0
    }

    .mt0 {
      margin-top: 0
    }

    .mb5 {
      margin-bottom: 5px
    }

    .mb0 {
      margin-bottom: 0;
    }

    .upper {
      text-transform: uppercase
    }

    .inline-block {
      display: inline-block
    }

    .checklist div {
      padding-left: 20px;
      margin-top: 15px;
      position: relative;
    }

    .checklist div:before {
      content: url("https://devwl.storagefront.com/media/mjml_images/check.png");
      position: absolute;
      left: -10px;
      top: -1px
    }

    .mt5 {
      margin-top: 5px;
    }

    .min-btn-width {
      max-width: 193px;
    }

    .reduced-content {
      padding-left: 40px;
      padding-right: 40px;
    }

    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .space-between {
      justify-content: space-between;
    }

    .footer-links {
      width: 355px;
    }

    .checklist-title {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .checklist {
      padding: 10px 20px !important;
    }

    .rental-summary {
      padding-left: 20px !important;
    }

    .facility-info {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .desktop-plr30 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .contact-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .contact-wrapper .inline-block {
      position: relative;
    }

    .contact-wrapper .inline-block p {
      position: absolute;
      width: 40px;
    }

    .contact-wrapper .inline-block p.directions {
      width: 75px;
      left: -16px;
    }

    .contact-wrapper .inline-block p.billpay {
      width: 48px;
      left: -5px
    }

    .social-media-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 20px;
    }

    ul.reservation_price {
      list-style: none;
      padding-left: 0;
    }

    ul.reservation_price .in_store p.last_text {
      text-decoration: line-through;
    }

    ul.reservation_price li {
      float: left;
      width: 49%;
      text-align: center;
      margin-left: 0;
    }

    ul.reservation_price li.strong {
      font-weight: 700;
    }

    ul.reservation_price li.border {
      border-right: 1px solid grey;
    }

    ul.reservation_price li p {
      font-size: 24px;
      margin-top: 2px;
      margin-bottom: 0;
    }

    ul.reservation_price li p.title {
      font-size: 11px;
    }

    .striked_price {
      text-decoration: line-through;
    }

    @media only screen and (max-width: 480px) {
      .call-directions {
        width: 300px !important;
      }

      .mobile-social-wrapper {
        width: 120px !important;
      }

      .spacer {
        display: none;
      }

      .reduced-content {
        padding-left: 30px;
        padding-right: 30px;
      }

      .checklist-title {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .checklist {
        padding: 10px 10px !important;
      }

      .rental-summary {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .facility-info {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .mobile-plr30 {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .video-content {
        padding-left: 50px !important;
        padding-right: 50px !important;
      }
    }

    @media only screen and (min-width: 480px) {
      .video-thumbnail {
        padding: 0 !important;
      }

      .video-thumbnail img {
        width: 300px !important;
      }

      .video-button table {
        width: 193px !important;
      }

      .desktop-pl0 {
        padding-left: 0 !important;
      }

      .desktop-plt0 {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }

      .desktop-plt0 img {
        width: 275px !important;
      }

      .desktop-prb0 {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }

      .desktop-prb0 img {
        width: 275px !important;
      }

      .desktop-pt40 {
        padding-top: 40px !important;
      }

      .mobile-spacer {
        display: none;
      }

      .desktop-pt25 {
        padding-top: 25px !important;
      }
    }
  </style>
</head>

<body style="word-spacing:normal;background-color:#F9F9F9;">
<div style="background-color:#F9F9F9;">
  <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:600px;">
                        <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email_triangle.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="600" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;padding-right:40px;padding-bottom:0px;padding-left:40px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:18px;line-height:23px;text-align:left;color:#000000;">Dear Lance,</div>
                </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:40px;padding-left:40px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:23px;text-align:left;color:#000000;">Thank you for your reservation</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:250px;">
                        <img height="auto" src="https://res.cloudinary.com/storelocal/image/fetch/q_auto/https://dr2r4w0s7b8qm.cloudfront.net/image_manager_app/Desktop_-_Storelocal_2023_-20240124-005700.svg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><v:rect style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false"><v:fill origin="0.5, 0" position="0.5, 0" src="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" color="#FFFFFF" type="tile" size="1,1" aspect="atleast" /><v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0"><![endif]-->
  <div style="background:#FFFFFF url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;margin:0px auto;max-width:600px;">
    <div style="line-height:0;font-size:0;">
      <table align="center" background="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;width:100%;">
        <tbody>
        <tr>
          <td style="direction:ltr;font-size:0px;padding:25px;text-align:right;">
            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:275px;" ><![endif]-->
            <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td class="mobile-spacer" style="font-size:0px;word-break:break-word;">
                    <div style="height:150px;line-height:150px;">&#8202;</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:220px;" ><![endif]-->
            <div class="mj-column-px-220 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:white;vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:10px 25px;padding-top:30px;padding-bottom:5px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:1;text-align:center;text-transform:capitalize;color:#000000;">Your Reservation code</div>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:18px;line-height:28px;text-align:center;color:#000000;">*64747363#</div>
                  </td>
                </tr>
                <!-- <mj-button background-color="black" color="white" font-family="Montserrat, Arial" font-weight="700" font-size="16px" width="170px">10' X 10'<br/>Unit C768</mj-button> -->
                <tr>
                  <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:1;text-align:center;color:#000000;">
                      <hr style="margin: 0; border: 0.5px solid black;">
                      </hr>
                      <p style="margin-bottom: 5px;">10' X 10'</p>
                      <p style="margin-top: 5px;">Unit C768</p>
                      <hr style="margin: 0; border: 0.5px solid black;">
                      </hr>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">1710 West 2nd St,<br />Pomona CA, 91766</div>
                  </td>
                </tr>
                <!-- <mj-button background-color="white" color="black" font-family="Montserrat, Arial" font-weight="700" border="2px solid red" border-radius="5px" width="170px" padding-top="0" padding-bottom="20px">Get Directions</mj-button> -->
                <tr>
                  <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;padding-bottom:20px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">
                      <div style="border: 2px solid red; border-radius:5px; padding-top: 10px; padding-bottom: 10px;">
                        <a style="padding: 10px 25px;">Get Directions</a>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td></tr></table><![endif]-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--[if mso | IE]></v:textbox></v:rect></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="checklist-wrapper-outlook" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div class="checklist-wrapper" style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px; border:4px solid #F26500;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:25px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="checklist-title" style="font-size:0px;padding:10px 25px;padding-bottom:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">What's the next step?</div>
                </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0;padding-right:30px;padding-left:30px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                    <!--
    <div class="checklist">
      <div>Show up at your facility on or before your move-in date.</div>
      <div>Call your facility manager at (949) 456-4653 with any questions you might have.</div>
      <div>If you decided you need a different unit, we can make that change for you</div>
      <div>A lock maybe required and is available at your facility.</div>
      <div>Moving supplies are available at the facility.</div>
    </div>
    -->
                    <ul class="email-notes">
                      <li>Show up at your facility on or before your move-in date.</li>
                      <li>Call your facility manager at (949) 456-4653 with any questions you might have.</li>
                      <li>If you decided you need a different unit, we can make that change for you</li>
                      <li>A lock maybe required and is available at your facility.</li>
                      <li>Moving supplies are available at the facility.</li>
                    </ul>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#fbf4f0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#fbf4f0;background-color:#fbf4f0;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fbf4f0;background-color:#fbf4f0;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                    <h3 style="font-size: 20px; line-height: 28px;">Account Summary</h3>
                    <p class="mb0">Lance Watkins</p>
                    <p class="mt5 mb0">lance.watkins@storelocal.com</p>
                    <p class="mt5">(949) 657-7654</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:600;line-height:1;text-align:left;text-transform:uppercase;color:#000000;">your space</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">10' x 10' Unit</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Unit C768 | Economy</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:0px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">1st floor, Outdoor access</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Household storage(No vehicles)</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:5px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Move-In Date: </span>Oct. 20, 2019</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Access Code: </span>*64747363#</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="cost-table bold rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                  <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                    <tr>
                      <td>In-Store:</td>
                      <td class="striked_price">$ 136.00</td>
                    </tr>
                    <tr class="bold">
                      <td>Web Only:</td>
                      <td>$ 50.00</td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:260px;" ><![endif]-->
          <div class="mj-column-px-260 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td class="mobile-spacer" style="font-size:0px;word-break:break-word;">
                  <div style="height:20px;line-height:20px;">&#8202;</div>
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:70px;">
                        <img height="62" src="https://devwl.storagefront.com/media/mjml_images/acc.png" style="border:0;display:block;outline:none;text-decoration:none;height:62px;width:100%;font-size:13px;" width="70" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:1;text-align:center;color:#000000;">Rent Now and Save 30 minutes on your Move-In Day!</div>
                </td>
              </tr>
              <!-- <mj-text>
  <mj-raw>
    <ul class="reservation_price">
      <li class="border bold">
        <p class="title">WEB ONLY</p>
        <p>$148</p>
      </li>
      <li class="in_store">
        <p class="title">IN-STORE</p>
        <p class = "last_text">$160</p>
      </li>
    </ul>
  </mj-raw>
  </mj-text> -->
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                    <tr>
                      <td style="text-align: center; width: 50%; border-right: 1px solid black; font-weight: 700;">
                        <p style="font-size: 11px; margin-bottom: 0; margin-top: 0;">WEB ONLY</p>
                        <p style="font-size: 24px; margin-top: 0; margin-bottom: 5px;">$148</p>
                      </td>
                      <td style="text-align: center; width: 50%;">
                        <p style="font-size: 11px; margin-bottom: 0; margin-top: 0;">IN STORE</p>
                        <p style="font-size: 24px; margin-top: 0; margin-bottom: 5px; text-decoration: line-through;">$138</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid #973f45;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Express Rental </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:left;color:#000000;">50% OFF First Month's Rent for advanced Reservations</div>
                </td>
              </tr>
              <tr>
                <td class="spacer" style="font-size:0px;word-break:break-word;">
                  <div style="height:74px;line-height:74px;">&#8202;</div>
                </td>
              </tr>
              <tr>
                <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Go to My Account </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Add Date to Calendar </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Print this email </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:30px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">Facility Information</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">We're looking forward to meeting you</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;text-transform:uppercase;color:#000000;">ADDRESS</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                    <p class="mb0">West Coast Storage Outlet</p>
                    <p class="mt0 mb0">1710 West 2nd St</p>
                    <p class="mt0">Pomona, CA, 91766</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="left" vertical-align="middle" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Get Directions </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="test-outlook" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix test" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="upper mobile-plr30" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;color:#000000;">hours of operation</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:15px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Office Hours</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                  <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                    <tr>
                      <td>M-F</td>
                      <td>9:30am - 6:00pm</td>
                    </tr>
                    <tr>
                      <td>SAT</td>
                      <td>10:00am - 6:00pm</td>
                    </tr>
                    <tr>
                      <td>SUN</td>
                      <td>10:00am - 6:00pm</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:17px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Gate Hours</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                  <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                    <tr>
                      <td>M-F</td>
                      <td>9:30am - 6:00pm</td>
                    </tr>
                    <tr>
                      <td>SAT</td>
                      <td>10:00am - 6:00pm</td>
                    </tr>
                    <tr>
                      <td>SUN</td>
                      <td>10:00am - 6:00pm</td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:550px;">
                        <img height="auto" src="https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=AIzaSyDNGT1HVrJPJBu_V8N4sXcZjAYS9OVkYBk" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" class="desktop-prb0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:250px;">
                        <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image1.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="video-content desktop-pt25" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">You Can't Put a Price on Peace of Mind</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                </td>
              </tr>
              <tr>
                <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Protection Plan </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:rtl;font-size:0px;padding:20px 0;padding-top:0px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" class="desktop-plt0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:250px;">
                        <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image2.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
          <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" class="video-content desktop-pt40" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">Never pay a late fee again</div>
                </td>
              </tr>
              <tr>
                <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                </td>
              </tr>
              <tr>
                <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                    <tr>
                      <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                        <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Setup Autopay </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="call-directions-outlook" style="width:300px;" ><![endif]-->
          <div class="mj-column-px-300 mj-outlook-group-fix call-directions" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
            <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:52px;" ><![endif]-->
            <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                      <tbody>
                      <tr>
                        <td style="width:40px;">
                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/call.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CALL</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:84px;" ><![endif]-->
            <div class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:28%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                      <tbody>
                      <tr>
                        <td style="width:40px;">
                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/dire.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">DIRECTIONS</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
            <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                      <tbody>
                      <tr>
                        <td style="width:40px;">
                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/chat.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CHAT</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
            <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                      <tbody>
                      <tr>
                        <td style="width:40px;">
                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">MAIL</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:58px;" ><![endif]-->
            <div class="mj-column-per-19-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:19.5%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                      <tbody>
                      <tr>
                        <td style="width:40px;">
                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/billpay.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                    <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">BILLPAY</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td></tr></table><![endif]-->
          </div>
          <!--[if mso | IE]></td><![endif]-->
          <!-- <mj-column width="320px">
    <mj-text align="center" css-class="upper" padding-left="20px" padding-right="20px" font-size="11px" padding-bottom="0">
      <div class="contact-wrapper" style="display: flex; justify-content: space-between;">
        <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/call.png"></img>
          <p>call</p>
        </div>
        <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/dire.png"></img>
          <p class="directions">Directions</p>
        </div>
        <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/chat.png"></img>
          <p>Chat</p>
        </div>
        <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/email.png"></img>
          <p>Email</p>
        </div>
        <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/billpay.png"></img>
          <p class="billpay">Billpay</p>
        </div>
      </div>
    </mj-text>
  </mj-column> -->
          <!--[if mso | IE]></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-top:35px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:600;line-height:1;text-align:center;color:#000000;">1(800) 453-7889</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><![endif]-->
  <!-- <mj-section background-color="black">
    <mj-column>
      <mj-text align="center" color="white"><span class="bold">Follow</span> Demo Storage

        <div class="social-media-wrapper"><img src="https://devwl.storagefront.com/media/mjml_images/fb.png"></img><img src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="padding: 0 20px"></img><img src="https://devwl.storagefront.com/media/mjml_images/gplus.png"></img>
        </div>
      </mj-text>
      <mj-image width="190px" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png"></mj-image>
    </mj-column>
  </mj-section> -->
  <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:10px;padding-top:15px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:white;"><span class="bold">Follow</span> Demo Storage</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="mobile-social-wrapper-outlook" style="vertical-align:middle;width:120px;" ><![endif]-->
          <div class="mj-column-px-120 mj-outlook-group-fix mobile-social-wrapper" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:middle;">
            <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:39px;" ><![endif]-->
            <div class="mj-column-per-33 mj-outlook-group-fix testing" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                <tbody>
                <tr>
                  <td style="vertical-align:top;padding:0;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                      <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                            <tr>
                              <td style="width:14px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="14" />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:48px;" ><![endif]-->
            <div class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:40%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                <tbody>
                <tr>
                  <td style="vertical-align:top;padding:0;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                      <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                            <tr>
                              <td style="width:24px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="24" />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td><td style="vertical-align:top;width:32px;" ><![endif]-->
            <div class="mj-column-per-27 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:27%;">
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                <tbody>
                <tr>
                  <td style="vertical-align:top;padding:0;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                      <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                            <tr>
                              <td style="width:16px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/gplus.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="16" />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--[if mso | IE]></td></tr></table><![endif]-->
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:10px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                    <tbody>
                    <tr>
                      <td style="width:190px;">
                        <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="190" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:left;color:#000000;">This email was sent by: Demo Storage, 6885 Alton Pkwy, #200, Irvince CA 92618. To update your profile <a href="">go here.</a> The information obtained on this email message is confidential and intended only for the recipient to which it was addressed</div>
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">&copy; 2019 Demo Storage. All Rights Reserved.</div>
                </td>
              </tr>
              <!-- <mj-text align="center" mj-class="bold">
    <div class="flex space-between footer-links">
    <a href="">Privacy Policy</a> | <a href="">Unsubscribe</a> | <a href="">View in Browser</a></div></mj-text> -->
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
  <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
      <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:110px;" ><![endif]-->
          <div class="mj-column-px-110 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Privacy Policy</a></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
          <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:105px;" ><![endif]-->
          <div class="mj-column-px-105 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Unsubscribe</a></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
          <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:130px;" ><![endif]-->
          <div class="mj-column-px-130 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
              <tr>
                <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                  <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">View in Browser</a></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]></td></tr></table><![endif]-->
</div>
</body>
</html>
`,
  reservation_soft: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>
  </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
  <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700" rel="stylesheet" type="text/css">
  <style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
  </style>
  <!--<![endif]-->
  <style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }

      .mj-column-per-50 {
        width: 50% !important;
        max-width: 50%;
      }

      .mj-column-px-220 {
        width: 220px !important;
        max-width: 220px;
      }

      .mj-column-px-260 {
        width: 260px !important;
        max-width: 260px;
      }

      .mj-column-px-300 {
        width: 300px !important;
        max-width: 300px;
      }

      .mj-column-per-17-5 {
        width: 17.5% !important;
        max-width: 17.5%;
      }

      .mj-column-per-28 {
        width: 28% !important;
        max-width: 28%;
      }

      .mj-column-per-19-5 {
        width: 19.5% !important;
        max-width: 19.5%;
      }

      .mj-column-px-120 {
        width: 120px !important;
        max-width: 120px;
      }

      .mj-column-per-33 {
        width: 33% !important;
        max-width: 33%;
      }

      .mj-column-per-40 {
        width: 40% !important;
        max-width: 40%;
      }

      .mj-column-per-27 {
        width: 27% !important;
        max-width: 27%;
      }

      .mj-column-px-110 {
        width: 110px !important;
        max-width: 110px;
      }

      .mj-column-px-5 {
        width: 5px !important;
        max-width: 5px;
      }

      .mj-column-px-105 {
        width: 105px !important;
        max-width: 105px;
      }

      .mj-column-px-130 {
        width: 130px !important;
        max-width: 130px;
      }
    }
  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }

    .moz-text-html .mj-column-per-50 {
      width: 50% !important;
      max-width: 50%;
    }

    .moz-text-html .mj-column-px-220 {
      width: 220px !important;
      max-width: 220px;
    }

    .moz-text-html .mj-column-px-260 {
      width: 260px !important;
      max-width: 260px;
    }

    .moz-text-html .mj-column-px-300 {
      width: 300px !important;
      max-width: 300px;
    }

    .moz-text-html .mj-column-per-17-5 {
      width: 17.5% !important;
      max-width: 17.5%;
    }

    .moz-text-html .mj-column-per-28 {
      width: 28% !important;
      max-width: 28%;
    }

    .moz-text-html .mj-column-per-19-5 {
      width: 19.5% !important;
      max-width: 19.5%;
    }

    .moz-text-html .mj-column-px-120 {
      width: 120px !important;
      max-width: 120px;
    }

    .moz-text-html .mj-column-per-33 {
      width: 33% !important;
      max-width: 33%;
    }

    .moz-text-html .mj-column-per-40 {
      width: 40% !important;
      max-width: 40%;
    }

    .moz-text-html .mj-column-per-27 {
      width: 27% !important;
      max-width: 27%;
    }

    .moz-text-html .mj-column-px-110 {
      width: 110px !important;
      max-width: 110px;
    }

    .moz-text-html .mj-column-px-5 {
      width: 5px !important;
      max-width: 5px;
    }

    .moz-text-html .mj-column-px-105 {
      width: 105px !important;
      max-width: 105px;
    }

    .moz-text-html .mj-column-px-130 {
      width: 130px !important;
      max-width: 130px;
    }
  </style>
  <style type="text/css">
    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }

      td.mj-full-width-mobile {
        width: auto !important;
      }
    }
  </style>
  <style type="text/css">
    a {
      text-decoration: none;
    }

    a:visited {
      color: black;
    }

    .bold {
      font-weight: 700;
    }

    .black-bg {
      background-color: black;
    }

    .p10 {
      padding: 10px;
    }

    .w50 {
      width: 50%;
    }

    .mauto {
      margin: 0 auto;
    }

    .right {
      float: right
    }

    .cost-table tr td:last-child {
      text-align: right
    }

    .pt0 {
      padding-top: 0
    }

    .mt0 {
      margin-top: 0
    }

    .mb5 {
      margin-bottom: 5px
    }

    .mb0 {
      margin-bottom: 0;
    }

    .upper {
      text-transform: uppercase
    }

    .inline-block {
      display: inline-block
    }

    .checklist div {
      padding-left: 20px;
      margin-top: 15px;
      position: relative;
    }

    .checklist div:before {
      content: url("https://devwl.storagefront.com/media/mjml_images/check.png");
      position: absolute;
      left: -10px;
      top: -1px
    }

    .mt5 {
      margin-top: 5px;
    }

    .min-btn-width {
      max-width: 193px;
    }

    .reduced-content {
      padding-left: 40px;
      padding-right: 40px;
    }

    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .space-between {
      justify-content: space-between;
    }

    .footer-links {
      width: 355px;
    }

    .checklist-title {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .checklist {
      padding: 10px 20px !important;
    }

    .rental-summary {
      padding-left: 20px !important;
    }

    .facility-info {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .desktop-plr30 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .contact-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .contact-wrapper .inline-block {
      position: relative;
    }

    .contact-wrapper .inline-block p {
      position: absolute;
      width: 40px;
    }

    .contact-wrapper .inline-block p.directions {
      width: 75px;
      left: -16px;
    }

    .contact-wrapper .inline-block p.billpay {
      width: 48px;
      left: -5px
    }

    .social-media-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 20px;
    }

    .call-to-action-button tr {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
    }

    @media only screen and (max-width: 480px) {
      .mob-align-center div {
        text-align: center !important;
      }

      .call-directions {
        width: 300px !important;
      }

      .mobile-social-wrapper {
        width: 120px !important;
      }

      .spacer {
        display: none;
      }

      .reduced-content {
        padding-left: 30px;
        padding-right: 30px;
      }

      .checklist-title {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .checklist {
        padding: 10px 10px !important;
      }

      .rental-summary {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .facility-info {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .mobile-plr30 {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .video-content {
        padding-left: 50px !important;
        padding-right: 50px !important;
      }

      .highlights {
        width: 220px !important;
        display: block !important;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: 480px) {
      .desktop-pl35 {
        padding-left: 35px !important;
      }

      .video-thumbnail {
        padding: 0 !important;
      }

      .video-thumbnail img {
        width: 300px !important;
      }

      .video-button table {
        width: 251px !important;
      }

      .desktop-pl0 {
        padding-left: 0 !important;
      }

      .desktop-plt0 {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }

      .desktop-plt0 img {
        width: 275px !important;
      }

      .desktop-prb0 {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }

      .desktop-prb0 img {
        width: 275px !important;
      }

      .desktop-pt40 {
        padding-top: 40px !important;
      }

      .mobile-spacer {
        display: none;
      }

      .desktop-pt25 {
        padding-top: 25px !important;
      }
    }
  </style>
</head>

<body style="word-spacing:normal;background-color:#F9F9F9;">
  <div style="background-color:#F9F9F9;">
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#F9F9F9" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#F9F9F9;background-color:#F9F9F9;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F9F9F9;background-color:#F9F9F9;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:600px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email_triangle.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="600" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-right:40px;padding-bottom:0px;padding-left:40px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;line-height:23px;text-align:left;color:#000000;">Dear Lance,</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:40px;padding-left:40px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:23px;text-align:left;color:#000000;">Your unit is on hold.</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://res.cloudinary.com/storelocal/image/fetch/q_auto/https://dr2r4w0s7b8qm.cloudfront.net/image_manager_app/Desktop_-_Storelocal_2023_-20240124-005700.svg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#fbf4f0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#fbf4f0;background-color:#fbf4f0;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fbf4f0;background-color:#fbf4f0;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:1;text-align:left;color:#000000;">Important Notice</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:left;color:#000000;">Due to very high demand at this facility, your unit will not be held for long. <span class="bold">Please contact the facility as soon as possible to complete your reservation (949) 456-4653 .</span></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td style="font-size:0px;word-break:break-word;">
                        <div style="height:10px;line-height:10px;">&#8202;</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-outlook" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><v:rect style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false"><v:fill origin="0.5, 0" position="0.5, 0" src="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" color="#FFFFFF" type="tile" size="1,1" aspect="atleast" /><v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0"><![endif]-->
    <div class="section" style="background:#FFFFFF url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;margin:0px auto;max-width:600px;">
      <div style="line-height:0;font-size:0;">
        <table align="center" background="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:25px;text-align:right;">
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:275px;" ><![endif]-->
                <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td class="mobile-spacer" style="font-size:0px;word-break:break-word;">
                          <div style="height:150px;line-height:150px;">&#8202;</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td class="highlights-outlook" style="vertical-align:top;width:220px;" ><![endif]-->
                <div class="mj-column-px-220 mj-outlook-group-fix highlights" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:white;vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:30px;padding-bottom:5px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:1;text-align:center;text-transform:capitalize;color:#000000;">Call to confirm</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:22px;line-height:28px;text-align:center;color:#000000;">(949) 456-4653</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:170px;line-height:100%;">
                            <tr>
                              <td align="center" bgcolor="black" role="presentation" style="border:none;border-radius:3px;cursor:auto;mso-padding-alt:10px 25px;background:black;" valign="middle">
                                <p style="display:inline-block;width:120px;background:black;color:white;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"> 10' X 10'<br />
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">1710 West 2nd St,<br />Pomona CA, 91766</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:0;padding-bottom:20px;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:170px;line-height:100%;">
                            <tr>
                              <td align="center" bgcolor="white" role="presentation" style="border:2px solid red;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                                <p style="display:inline-block;width:150px;background:white;color:black;font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Get Directions </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--[if mso | IE]></v:textbox></v:rect></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="checklist-wrapper-outlook" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="checklist-wrapper" style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px; border:4px solid #F26500;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:25px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="checklist-title" style="font-size:0px;padding:10px 25px;padding-bottom:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">What's the next step?</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0;padding-right:30px;padding-left:30px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <div class="checklist">
                            <div>Show up at your facility on or before your move-in date.</div>
                            <div>Call your facility manager at (949) 456-4653 with any questions you might have.</div>
                            <div>If you decided you need a different unit, we can make that change for you</div>
                            <div>A lock maybe required and is available at your facility.</div>
                            <div>Moving supplies are available at the facility.</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#fbf4f0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#fbf4f0;background-color:#fbf4f0;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fbf4f0;background-color:#fbf4f0;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <h3 style="font-size: 20px; line-height: 28px;">Reservation Summary</h3>
                          <p class="mb0">Lance Watkins</p>
                          <p class="mt0 mb0">lance.watkins@storelocal.com</p>
                          <p class="mt0">(949) 657-7654</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:600;line-height:1;text-align:left;text-transform:uppercase;color:#000000;">your space</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">10' x 10' Unit</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Unit C768 | Economy</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">1st floor, Outdoor access</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Household storage(No vehicles)</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Move-In Date: </span>Oct. 20, 2019</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Access Code: </span>*64747363#</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table bold rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr>
                            <td>In-Store:</td>
                            <td class="striked_price">$ 136.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table bold rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr class="bold">
                            <td>Web Only:</td>
                            <td>$ 50.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:260px;" ><![endif]-->
              <div class="mj-column-px-260 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td class="spacer" style="font-size:0px;word-break:break-word;">
                        <div style="height:5px;line-height:5px;">&#8202;</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:1px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Go to My Account </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:1px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Add Date to Calendar </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:1px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Print this email </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:30px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">Facility Information</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">We're looking forward to meeting you</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;text-transform:uppercase;color:#000000;">ADDRESS</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <p class="mb0">West Coast Storage Outlet</p>
                          <p class="mt0 mb0">1710 West 2nd St</p>
                          <p class="mt0">Pomona, CA, 91766</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:1px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Get Directions </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="upper mobile-plr30" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;color:#000000;">hours of operation</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:15px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Office Hours</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                          <tr>
                            <td>M-F</td>
                            <td>9:30am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SAT</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SUN</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:17px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Gate Hours</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                          <tr>
                            <td>M-F</td>
                            <td>9:30am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SAT</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SUN</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:550px;">
                                <img height="auto" src="https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=AIzaSyDNGT1HVrJPJBu_V8N4sXcZjAYS9OVkYBk" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="desktop-prb0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image1.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="video-content desktop-pt25" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">You Can't Put a Price on Peace of Mind</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:3px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"> Protection Plan </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:rtl;font-size:0px;padding:20px 0;padding-top:0px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="desktop-plt0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image2.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="video-content desktop-pt40" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">Never pay a late fee again</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:3px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"> Setup Autopay </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="call-directions-outlook" style="width:300px;" ><![endif]-->
              <div class="mj-column-px-300 mj-outlook-group-fix call-directions" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
                <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/call.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CALL</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:84px;" ><![endif]-->
                <div class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:28%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/dire.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">DIRECTIONS</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/chat.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CHAT</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">MAIL</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:58px;" ><![endif]-->
                <div class="mj-column-per-19-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:19.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/billpay.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">BILLPAY</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </div>
              <!--[if mso | IE]></td><![endif]-->
              <!-- <mj-column width="320px">
        <mj-text align="center" css-class="upper" padding-left="20px" padding-right="20px" font-size="11px" padding-bottom="0">
          <div class="contact-wrapper" style="display: flex; justify-content: space-between;">
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/call.png"></img>
              <p>call</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/dire.png"></img>
              <p class="directions">Directions</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/chat.png"></img>
              <p>Chat</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/email.png"></img>
              <p>Email</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/billpay.png"></img>
              <p class="billpay">Billpay</p>
            </div>
          </div>
        </mj-text>
      </mj-column> -->
              <!--[if mso | IE]></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-top:35px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:600;line-height:1;text-align:center;color:#000000;">1(800) 453-7889</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <!-- <mj-section background-color="black">
      <mj-column>
        <mj-text align="center" color="white"><span class="bold">Follow</span> Demo Storage

          <div class="social-media-wrapper"><img src="https://devwl.storagefront.com/media/mjml_images/fb.png"></img><img src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="padding: 0 20px"></img><img src="https://devwl.storagefront.com/media/mjml_images/gplus.png"></img>
          </div>
        </mj-text>
        <mj-image width="190px" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png"></mj-image>
      </mj-column>
    </mj-section> -->
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:10px;padding-top:15px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:white;"><span class="bold">Follow</span> Demo Storage</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="mobile-social-wrapper-outlook" style="vertical-align:middle;width:120px;" ><![endif]-->
              <div class="mj-column-px-120 mj-outlook-group-fix mobile-social-wrapper" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:middle;">
                <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:39px;" ><![endif]-->
                <div class="mj-column-per-33 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:14px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="14" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:48px;" ><![endif]-->
                <div class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:40%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:24px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="24" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:32px;" ><![endif]-->
                <div class="mj-column-per-27 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:27%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:16px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/gplus.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="16" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:190px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="190" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:18px;text-align:left;color:#000000;">This email was sent by: Demo Storage, 6885 Alton Pkwy, #200, Irvince CA 92618. To update your profile <a href="">go here.</a> The information obtained on this email message is confidential and intended only for the recipient to which it was addressed</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">&copy; 2019 Demo Storage. All Rights Reserved.</div>
                      </td>
                    </tr>
                    <!-- <mj-text align="center" mj-class="bold">
          <div class="flex space-between footer-links">
          <a href="">Privacy Policy</a> | <a href="">Unsubscribe</a> | <a href="">View in Browser</a></div></mj-text> -->
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#FFFFFF;background-color:#FFFFFF;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#FFFFFF;background-color:#FFFFFF;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:110px;" ><![endif]-->
              <div class="mj-column-px-110 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Privacy Policy</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
              <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:105px;" ><![endif]-->
              <div class="mj-column-px-105 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Unsubscribe</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
              <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:130px;" ><![endif]-->
              <div class="mj-column-px-130 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">View in Browser</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
  </div>
</body>

</html>`,
  rental_confirmation: `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title>
  </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }
  </style>
  <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
  <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700" rel="stylesheet" type="text/css">
  <style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
  </style>
  <!--<![endif]-->
  <style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }

      .mj-column-per-50 {
        width: 50% !important;
        max-width: 50%;
      }

      .mj-column-px-220 {
        width: 220px !important;
        max-width: 220px;
      }

      .mj-column-px-260 {
        width: 260px !important;
        max-width: 260px;
      }

      .mj-column-px-300 {
        width: 300px !important;
        max-width: 300px;
      }

      .mj-column-per-17-5 {
        width: 17.5% !important;
        max-width: 17.5%;
      }

      .mj-column-per-28 {
        width: 28% !important;
        max-width: 28%;
      }

      .mj-column-per-19-5 {
        width: 19.5% !important;
        max-width: 19.5%;
      }

      .mj-column-px-120 {
        width: 120px !important;
        max-width: 120px;
      }

      .mj-column-per-33 {
        width: 33% !important;
        max-width: 33%;
      }

      .mj-column-per-40 {
        width: 40% !important;
        max-width: 40%;
      }

      .mj-column-per-27 {
        width: 27% !important;
        max-width: 27%;
      }

      .mj-column-px-110 {
        width: 110px !important;
        max-width: 110px;
      }

      .mj-column-px-5 {
        width: 5px !important;
        max-width: 5px;
      }

      .mj-column-px-105 {
        width: 105px !important;
        max-width: 105px;
      }

      .mj-column-px-130 {
        width: 130px !important;
        max-width: 130px;
      }
    }
  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }

    .moz-text-html .mj-column-per-50 {
      width: 50% !important;
      max-width: 50%;
    }

    .moz-text-html .mj-column-px-220 {
      width: 220px !important;
      max-width: 220px;
    }

    .moz-text-html .mj-column-px-260 {
      width: 260px !important;
      max-width: 260px;
    }

    .moz-text-html .mj-column-px-300 {
      width: 300px !important;
      max-width: 300px;
    }

    .moz-text-html .mj-column-per-17-5 {
      width: 17.5% !important;
      max-width: 17.5%;
    }

    .moz-text-html .mj-column-per-28 {
      width: 28% !important;
      max-width: 28%;
    }

    .moz-text-html .mj-column-per-19-5 {
      width: 19.5% !important;
      max-width: 19.5%;
    }

    .moz-text-html .mj-column-px-120 {
      width: 120px !important;
      max-width: 120px;
    }

    .moz-text-html .mj-column-per-33 {
      width: 33% !important;
      max-width: 33%;
    }

    .moz-text-html .mj-column-per-40 {
      width: 40% !important;
      max-width: 40%;
    }

    .moz-text-html .mj-column-per-27 {
      width: 27% !important;
      max-width: 27%;
    }

    .moz-text-html .mj-column-px-110 {
      width: 110px !important;
      max-width: 110px;
    }

    .moz-text-html .mj-column-px-5 {
      width: 5px !important;
      max-width: 5px;
    }

    .moz-text-html .mj-column-px-105 {
      width: 105px !important;
      max-width: 105px;
    }

    .moz-text-html .mj-column-px-130 {
      width: 130px !important;
      max-width: 130px;
    }
  </style>
  <style type="text/css">
    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }

      td.mj-full-width-mobile {
        width: auto !important;
      }
    }
  </style>
  <style type="text/css">
    a {
      text-decoration: none;
    }

    .bold {
      font-weight: 700;
    }

    .black-bg {
      background-color: black;
    }

    .p10 {
      padding: 10px;
    }

    .w50 {
      width: 50%;
    }

    .mauto {
      margin: 0 auto;
    }

    .right {
      float: right
    }

    .cost-table tr td:last-child {
      text-align: right
    }

    .pt0 {
      padding-top: 0
    }

    .mt0 {
      margin-top: 0
    }

    .mb5 {
      margin-bottom: 5px
    }

    .mb0 {
      margin-bottom: 0;
    }

    .upper {
      text-transform: uppercase
    }

    .inline-block {
      display: inline-block
    }

    .checklist div {
      padding-left: 20px;
      margin-top: 15px;
      position: relative;
    }

    .checklist div:before {
      content: url("https://devwl.storagefront.com/media/mjml_images/check.png");
      position: absolute;
      left: -10px;
      top: -1px
    }

    .mt5 {
      margin-top: 5px;
    }

    .min-btn-width {
      max-width: 193px;
    }

    .reduced-content {
      padding-left: 40px;
      padding-right: 40px;
    }

    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .space-between {
      justify-content: space-between;
    }

    .footer-links {
      width: 355px;
    }

    .checklist-title {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .checklist {
      padding: 10px 20px !important;
    }

    .rental-summary {
      padding-left: 20px !important;
    }

    .facility-info {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .desktop-plr30 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .contact-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .contact-wrapper .inline-block {
      position: relative;
    }

    .contact-wrapper .inline-block p {
      position: absolute;
      width: 40px;
    }

    .contact-wrapper .inline-block p.directions {
      width: 75px;
      left: -16px;
    }

    .contact-wrapper .inline-block p.billpay {
      width: 48px;
      left: -5px
    }

    .social-media-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 20px;
    }

    @media only screen and (max-width: 480px) {
      .call-directions {
        width: 300px !important;
      }

      .mobile-social-wrapper {
        width: 120px !important;
      }

      .spacer {
        display: none;
      }

      .reduced-content {
        padding-left: 30px;
        padding-right: 30px;
      }

      .checklist-title {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .checklist {
        padding: 10px 10px !important;
      }

      .rental-summary {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .facility-info {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .mobile-plr30 {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .video-content {
        padding-left: 50px !important;
        padding-right: 50px !important;
      }
    }

    @media only screen and (min-width: 480px) {
      .video-thumbnail {
        padding: 0 !important;
      }

      .video-thumbnail img {
        width: 300px !important;
      }

      .video-button table {
        width: 193px !important;
      }

      .desktop-pl0 {
        padding-left: 0 !important;
      }

      .desktop-plt0 {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }

      .desktop-plt0 img {
        width: 275px !important;
      }

      .desktop-prb0 {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
      }

      .desktop-prb0 img {
        width: 275px !important;
      }

      .desktop-pt40 {
        padding-top: 40px !important;
      }

      .mobile-spacer {
        display: none;
      }

      .desktop-pt25 {
        padding-top: 25px !important;
      }
    }
  </style>
</head>

<body style="word-spacing:normal;">
  <div style="">
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:600px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email_triangle.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="600" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-right:40px;padding-bottom:0px;padding-left:40px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;line-height:23px;text-align:left;color:#000000;">Dear Lance,</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:40px;padding-left:40px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:23px;text-align:left;color:#000000;">You have successfully completed your rental</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://res.cloudinary.com/storelocal/image/fetch/q_auto/https://dr2r4w0s7b8qm.cloudfront.net/image_manager_app/Desktop_-_Storelocal_2023_-20240124-005700.svg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><v:rect style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false"><v:fill origin="0.5, 0" position="0.5, 0" src="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" type="tile" size="1,1" aspect="atleast" /><v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0"><![endif]-->
    <div style="background:url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;margin:0px auto;max-width:600px;">
      <div style="line-height:0;font-size:0;">
        <table align="center" background="https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:url(https://devwl.storagefront.com/media/mjml_images/bg_desktop.jpg) center top / cover repeat;background-position:center top;background-repeat:repeat;background-size:cover;width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:25px;text-align:right;">
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:275px;" ><![endif]-->
                <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td class="mobile-spacer" style="font-size:0px;word-break:break-word;">
                          <div style="height:150px;line-height:150px;">&#8202;</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:220px;" ><![endif]-->
                <div class="mj-column-px-220 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:white;vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:30px;padding-bottom:5px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:1;text-align:center;text-transform:capitalize;color:#000000;">Your access code</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:22px;line-height:28px;text-align:center;color:#000000;">*64747363#</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:170px;line-height:100%;">
                            <tr>
                              <td align="center" bgcolor="black" role="presentation" style="border:none;border-radius:3px;cursor:auto;mso-padding-alt:10px 25px;background:black;" valign="middle">
                                <p style="display:inline-block;width:120px;background:black;color:white;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"> 10' X 10'<br />Unit C768 </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">1710 West 2nd St,<br />Pomona CA, 91766</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:0;padding-bottom:20px;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:170px;line-height:100%;">
                            <tr>
                              <td align="center" bgcolor="white" role="presentation" style="border:2px solid red;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                                <p style="display:inline-block;width:116px;background:white;color:black;font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Get Directions </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--[if mso | IE]></v:textbox></v:rect></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="checklist-wrapper-outlook" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="checklist-wrapper" style="margin:0px auto;max-width:600px;  border:4px solid #F26500;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:25px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="checklist-title" style="font-size:0px;padding:10px 25px;padding-bottom:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">What's the next step?</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;padding-top:0;padding-right:30px;padding-left:30px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <div class="checklist">
                            <div>Show up at your facility on or before your move-in date.</div>
                            <div>Call your facility manager at (949) 456-4653 with any questions you might have.</div>
                            <div>If you decided you need a different unit, we can make that change for you</div>
                            <div>A lock maybe required and is available at your facility.</div>
                            <div>Moving supplies are available at the facility.</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#fbf4f0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:#fbf4f0;background-color:#fbf4f0;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#fbf4f0;background-color:#fbf4f0;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <h3 style="font-size: 20px; line-height: 28px;">Account Summary</h3>
                          <p class="mb0">Lance Watkins</p>
                          <p class="mt5 mb0">lance.watkins@storelocal.com</p>
                          <p class="mt5">(949) 657-7654</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:600;line-height:1;text-align:left;text-transform:uppercase;color:#000000;">your space</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">10' x 10' Unit</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Unit C768 | Economy</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">1st floor, Outdoor access</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Household storage(No vehicles)</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Move-In Date: </span>Oct. 20, 2019</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;"><span class="bold">Access Code: </span>*64747363#</div>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size:0px;word-break:break-word;">
                        <div style="height:15px;line-height:15px;">&#8202;</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr>
                            <td>Normal Rate</td>
                            <td>$ 136.00</td>
                          </tr>
                          <tr>
                            <td>Online Special</td>
                            <td>$ 136.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table rental-summary" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr>
                            <td>50% off 1st Month Special</td>
                            <td>- $ 136.00</td>
                          </tr>
                          <tr>
                            <td>Administrative Fee</td>
                            <td>$ 136.00</td>
                          </tr>
                          <tr>
                            <td>Protection (pro-rated)</td>
                            <td>$ 136.00</td>
                          </tr>
                          <tr>
                            <td>Taxes</td>
                            <td>$ 136.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table bold rental-summary" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr class="bold">
                            <td>Paid Move In Cost:</td>
                            <td>$ 136.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table bold rental-summary" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr class="bold">
                            <td>Open Balance:</td>
                            <td>$ 00.00</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:260px;" ><![endif]-->
              <div class="mj-column-px-260 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td class="mobile-spacer" style="font-size:0px;word-break:break-word;">
                        <div style="height:20px;line-height:20px;">&#8202;</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:70px;">
                                <img height="62" src="https://devwl.storagefront.com/media/mjml_images/acc.png" style="border:0;display:block;outline:none;text-decoration:none;height:62px;width:100%;font-size:13px;" width="70" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;text-transform:uppercase;color:#000000;">save time &amp; money</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">Enjoy the convenience of autopay and never pay a late fee again.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid #973f45;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Setup Autopay </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td class="spacer" style="font-size:0px;word-break:break-word;">
                        <div style="height:155px;line-height:155px;">&#8202;</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Go to My Account </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Add Date to Calendar </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" class="min-btn-width" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Print this email </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:30px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:20px;font-weight:700;line-height:28px;text-align:left;color:#000000;">Facility Information</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">We're looking forward to meeting you</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:5px;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;text-transform:uppercase;color:#000000;">ADDRESS</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">
                          <p class="mb0">West Coast Storage Outlet</p>
                          <p class="mt0 mb0">1710 West 2nd St</p>
                          <p class="mt0">Pomona, CA, 91766</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" class="facility-info" style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:12px;font-weight:700;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Get Directions </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="test-outlook" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix test" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="upper mobile-plr30" style="font-size:0px;padding:10px 25px;padding-bottom:5px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:600;line-height:18px;text-align:left;color:#000000;">hours of operation</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:15px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Office Hours</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                          <tr>
                            <td>M-F</td>
                            <td>9:30am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SAT</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SUN</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:17px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;font-weight:700;line-height:18px;text-align:left;color:#000000;">Gate Hours</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="cost-table mobile-plr30" style="font-size:0px;padding:10px 25px;padding-top:5px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="200" border="0" style="color:#000000;font-family:Montserrat, Arial;font-size:14px;line-height:18px;table-layout:auto;width:200px;border:none;">
                          <tr>
                            <td>M-F</td>
                            <td>9:30am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SAT</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>SUN</td>
                            <td>10:00am - 6:00pm</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-bottom:0px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:550px;">
                                <img height="auto" src="https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=AIzaSyDNGT1HVrJPJBu_V8N4sXcZjAYS9OVkYBk" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="desktop-prb0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image1.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="video-content desktop-pt25" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">You Can't Put a Price on Peace of Mind</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Protection Plan </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:rtl;font-size:0px;padding:20px 0;padding-top:0px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="desktop-plt0" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:250px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/image2.jpg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="250" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:300px;" ><![endif]-->
              <div class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="video-content desktop-pt40" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:700;line-height:22px;text-align:left;color:#000000;">Never pay a late fee again</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" class="video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:14px;line-height:18px;text-align:left;color:#000000;">The stuff you put into your storage unit isn't just stuff. Watch our insurance guide video to find out what's best for you.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" vertical-align="middle" class="video-button video-content" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:100%;line-height:100%;">
                          <tr>
                            <td align="center" bgcolor="white" role="presentation" style="border:2px solid black;border-radius:5px;cursor:auto;mso-padding-alt:10px 25px;background:white;" valign="middle">
                              <p style="display:inline-block;background:white;color:black;font-family:Montserrat, Arial;font-size:16px;font-weight:700;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:5px;"> Setup Autopay </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="call-directions-outlook" style="width:300px;" ><![endif]-->
              <div class="mj-column-px-300 mj-outlook-group-fix call-directions" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
                <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/call.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CALL</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:84px;" ><![endif]-->
                <div class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:28%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/dire.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">DIRECTIONS</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/chat.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">CHAT</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:52px;" ><![endif]-->
                <div class="mj-column-per-17-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:17.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/email.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">MAIL</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:58px;" ><![endif]-->
                <div class="mj-column-per-19-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:19.5%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                            <tbody>
                              <tr>
                                <td style="width:40px;">
                                  <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/billpay.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="40" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="font-size:0px;padding:0;padding-top:10px;word-break:break-word;">
                          <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">BILLPAY</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </div>
              <!--[if mso | IE]></td><![endif]-->
              <!-- <mj-column width="320px">
        <mj-text align="center" css-class="upper" padding-left="20px" padding-right="20px" font-size="11px" padding-bottom="0">
          <div class="contact-wrapper" style="display: flex; justify-content: space-between;">
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/call.png"></img>
              <p>call</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/dire.png"></img>
              <p class="directions">Directions</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/chat.png"></img>
              <p>Chat</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/email.png"></img>
              <p>Email</p>
            </div>
            <div class="inline-block"><img src="https://devwl.storagefront.com/media/mjml_images/billpay.png"></img>
              <p class="billpay">Billpay</p>
            </div>
          </div>
        </mj-text>
      </mj-column> -->
              <!--[if mso | IE]></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:5px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-top:35px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:18px;font-weight:600;line-height:1;text-align:center;color:#000000;">1(800) 453-7889</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <!-- <mj-section background-color="black">
      <mj-column>
        <mj-text align="center" color="white"><span class="bold">Follow</span> Demo Storage

          <div class="social-media-wrapper"><img src="https://devwl.storagefront.com/media/mjml_images/fb.png"></img><img src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="padding: 0 20px"></img><img src="https://devwl.storagefront.com/media/mjml_images/gplus.png"></img>
          </div>
        </mj-text>
        <mj-image width="190px" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png"></mj-image>
      </mj-column>
    </mj-section> -->
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:10px;padding-top:15px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:white;"><span class="bold">Follow</span> Demo Storage</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="mobile-social-wrapper-outlook" style="vertical-align:middle;width:120px;" ><![endif]-->
              <div class="mj-column-px-120 mj-outlook-group-fix mobile-social-wrapper" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;vertical-align:middle;">
                <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:39px;" ><![endif]-->
                <div class="mj-column-per-33 mj-outlook-group-fix testing" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:14px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="14" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:48px;" ><![endif]-->
                <div class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:40%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:24px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/tweet.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="24" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td><td style="vertical-align:top;width:32px;" ><![endif]-->
                <div class="mj-column-per-27 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:27%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                    <tbody>
                      <tr>
                        <td style="vertical-align:top;padding:0;">
                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                            <tbody>
                              <tr>
                                <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                    <tbody>
                                      <tr>
                                        <td style="width:16px;">
                                          <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/gplus.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="16" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="black" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="background:black;background-color:black;margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:black;background-color:black;width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:190px;">
                                <img height="auto" src="https://devwl.storagefront.com/media/mjml_images/powered_storelocal.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="190" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:10px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:left;color:#000000;">This email was sent by: Demo Storage, 6885 Alton Pkwy, #200, Irvince CA 92618. To update your profile <a href="">go here.</a> The information obtained on this email message is confidential and intended only for the recipient to which it was addressed</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;line-height:1;text-align:center;color:#000000;">&copy; 2019 Demo Storage. All Rights Reserved.</div>
                      </td>
                    </tr>
                    <!-- <mj-text align="center" mj-class="bold">
          <div class="flex space-between footer-links">
          <a href="">Privacy Policy</a> | <a href="">Unsubscribe</a> | <a href="">View in Browser</a></div></mj-text> -->
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:110px;" ><![endif]-->
              <div class="mj-column-px-110 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Privacy Policy</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
              <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:105px;" ><![endif]-->
              <div class="mj-column-px-105 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-bottom:0px;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">Unsubscribe</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:5px;" ><![endif]-->
              <div class="mj-column-px-5 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="spacer" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;">|</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:130px;" ><![endif]-->
              <div class="mj-column-px-130 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;padding-right:0;padding-left:0;word-break:break-word;">
                        <div style="font-family:Montserrat, Arial;font-size:13px;font-weight:700;line-height:1;text-align:center;color:#000000;"><a href="">View in Browser</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
  </div>
</body>

</html>`,
});
