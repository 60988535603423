<template>

    <div style="background-color: #ffffff;" class="d-flex flex-column communication-center-container" >
      <hb-blade-header
        class="unified-communication-header"
        title="Communication Center"
        title-icon="mdi-forum"
        half
        :firstColumnOff="$vuetify.breakpoint.width <= 800? true : false"
        :leftAlignTitle="$vuetify.breakpoint.width <= 800 ? true : false"
        back-button-off
        :tooltip-off="$vuetify.breakpoint.xs"
        @close="onClose"
      >
      <template v-if="$vuetify.breakpoint.width <= 800">
        <help-juice blade-header />
      </template>
      </hb-blade-header>
          <div class="content-view py-0 flex-fill d-flex flex-column">
              <v-row>
                <v-col
                  cols="12"
                  xs="6"
                  sm="12"
                  md="5"
                  lg="5"
                  xl="5"
                  class="py-0 px-0"
                  :style="dynamicStyleLeftPanel"
                >
                  <tenant-leads-list 
                    @showInteraction="showInteractionDetails" 
                    :updatedInteractor="updatedInteractor"
                    @onOpen="onOpen"
                    @onClose="onClose"
                    :refreshData="refreshData"
                    :contact_id="contact_id"
                  ></tenant-leads-list>
                </v-col>
              
                <v-col
                  cols="12"
                  xs="6"
                  sm="6"
                  md="7"
                  lg="7"
                  xl="7"
                  class="py-0 px-0 chat-window"
                  :style="dynamicStyleRightPanel"
                  v-if="$vuetify.breakpoint.width >= 800"
                >
                <chat-window 
                    v-if="chatObj !== null && chatObj !== undefined"
                    @viewContact="showSideBlade"
                    :InteractionObj="chatObj"
                    :adjust-layout="true"
                    :blade_data="blade_data"
                    chatWindowHeight="medium"
                    layout="message-center"
                    @interactorUpdate="updateInteractor"
                    @updateBladeData="updateBladeData"
                    sectionIdentifier="unified-communications"
                  ></chat-window>
                </v-col>
                
                <v-bottom-sheet
                  v-model="showBottomSheet"
                  persistent
                  content-class="custom-bottom-sheet"
                  min-height="100vh"
                  height="100vh"
                  v-else
                >
                  <div class="backHeader">
                    <hb-blade-header
                      class="unified-communication-header"
                      half
                      :firstColumnOff="true"
                      :leftAlignTitle="$vuetify.breakpoint.width <= 800 ? true : false"
                      @close="onCloseBottomsheet"
                      @back="onCloseBottomsheet"
                      :tooltip-off="$vuetify.breakpoint.xs"
                    >
                    <template v-slot:title>
                        <v-row no-gutters @click="onCloseBottomsheet()">
                            <HbIcon mdi-code="mdi-system-navigation-custom-4" />
                            <p class="pl-2 hb-blade-header-wrapper-title font-weight-medium mb-0">Back to All Communications</p>
                        </v-row>
                    </template>
                  </hb-blade-header>
                  </div>
                  <chat-window 
                    v-if="chatObj !== null && chatObj !== undefined"
                    @viewContact="showSideBlade"
                    :InteractionObj="chatObj"
                    :adjust-layout="true"
                    :blade_data="blade_data"
                    chatWindowHeight="medium"
                    layout="message-center"
                    @interactorUpdate="updateInteractor"
                    @updateBladeData="updateBladeData"
                  ></chat-window>
                </v-bottom-sheet>
              </v-row>
          </div>
    </div>
    
</template>

<script>
    import { EventBus } from '../../../EventBus.js';
    import TenantAndLeadsList from '../../../components/communication_center/TenantAndLeadsList.vue';
    import ChatWindow from '../../../components/communication_center/ChatWindow.vue';  
    import api from '../../../assets/api.js';
    import { mapActions } from 'vuex';
    export default {
        name: "CommunicationCenter",
        data() {
            return {
                menuOptions: [
                    { label: 'All', key: 1 },
                    { label: 'Email', key: 2 },
                    { label: 'SMS', key: 3 },
                    { label: 'Phone', key: 4 },
                    { label: 'Notes', key: 5 },
                ],
                showBottomSheet: false,
                showAside: false,
                showDrawer: false,
                slideBladeStyle: '',
                contactId: '',
                chatObj: undefined,
                refreshData: false,
                updatedInteractor: undefined,
                re_render: false,
                threadStyle:{
                    height: '100%',
                },
            }
        },
        async created() {
            EventBus.$on('hide_drawer', this.close);  
        },
        destroyed() {
            EventBus.$off('hide_drawer', this.close);
        },
        components: {
            'tenant-leads-list': TenantAndLeadsList,
            'chat-window': ChatWindow,
        },
        props:  ["contact_id", 'blade_data'],
        computed: {
          dynamicStyleLeftPanel() {
            if(this.$vuetify.breakpoint.width <= 800) {
              return "max-width:100%;"
            } else {
              return "max-width:360px!important;";
            }
          },
          dynamicStyleRightPanel() {
            if(this.$vuetify.breakpoint.width <= 800) {
              return "min-width: calc(100%);"
            } else {
              return "min-width: calc(100% - 360px)!important;"
            }
          }
        },
        methods: {
          ...mapActions({
            getMessageCount: 'notificationsStore/getMessageCount'
          }),
          onClose () {
            this.getMessageCount();
            this.$emit('close')
            this.showAside = false;
        },
        onCloseBottomsheet () {
          this.showBottomSheet = false;
       },
        onOpen () {
          this.refreshData = false;
        },
            showSideBlade (contact_Id) {
            this.contactId = contact_Id
            if (this.$vuetify.breakpoint.lgAndUp) {
                this.showAside = !this.showAside
            } else{
                this.showDrawer = !this.showDrawer
            }
            },
            async updateInteractor(obj) {
            let response = await api.get(this, api.CONTACTS + obj.contact_id + '/interaction-details');
			this.updatedInteractor = response.interactor;
            },

            showInteractionDetails (obj) {
            this.chatObj = obj
            this.myId = obj.id
            if(this.$vuetify.breakpoint.width <= 800)
            {
              this.showBottomSheet = true;
              window.scrollTo(0,0);
            }
            },
    
            getWindowSize(){
            var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
            return {x,y}
            },
            updateBladeData(data) {
              this.blade_data = data;
            }
                }
            }
</script>
<!-- <style  lang="scss">

@media (min-width : 300px) and (max-width: 400px) {
  .unified-communication-header  {
    .hb-blade-header-wrapper-title {
        font-size: 14px !important;
        line-height: 23px;
    }
  }
}

@media (max-width: 428px) {
  .unified-communication-header  {
    .hb-blade-header-wrapper-title {
      font-size: 17px;
    }
  }
}

@media (max-width: 600px) {
  .communication-center-container {
    .hb-blade-header-wrapper {
      .hb-blade-header-card-wrapper {
        div:nth-child(1) {
          padding-left: 0px;
          justify-content: flex-start !important;
          position: relative;
          left: 20px;
          flex: 2 0 70%;
          max-width: 100%;
        }
      }
    }
  }
}

</style> -->
<style scoped>
    @media (min-width: 300px) and (max-width: 400px) {
      .unified-communication-header ::v-deep(.hb-blade-header-wrapper-title) {
        font-size: 14px !important;
        line-height: 23px;
      }
    }

    @media (max-width: 428px) {
      .unified-communication-header ::v-deep(.hb-blade-header-wrapper-title) {
        font-size: 17px;
      }
    }

    @media (max-width: 600px) {
      .communication-center-container ::v-deep(.hb-blade-header-wrapper .hb-blade-header-card-wrapper div:nth-child(1)) {
        padding-left: 0px;
        justify-content: flex-start !important;
        position: relative;
        left: 20px;
        flex: 2 0 70%;
        max-width: 100%;
      }
    }

    .communication-center-container {
    height: 100%;
    width: 100%;
  }

  ::v-deep .custom-bottom-sheet {
    position: absolute;
    top: 0px;
  }

  .unified-communication-header {
    flex: 0 0 auto;
  }

    .subHeading {
    font-size: 14px;
    vertical-align: top;
    letter-spacing: -0.2px;
    align-content: left;
    margin: 0px;
    }

    .section-content {
    padding-right: 0px !important;
    }

    .side-blade {
    width: 30%;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    box-shadow: 1px 1px 4px 0px #dee5e7;
    }
    .content-view {
    padding-right: 12px;
    padding-left: 12px;
    margin-top: 0 !important;
    border: none;
    box-shadow: none !important;
    }
    .chat-window{
    border-color: rgba(11, 18, 29, 0.1);
    border-style: solid;
    border-width: 0px 1px 0px 1px;
    }

    .backHeader {
      background-color: rgb(249, 250, 251)
    }

    *::v-deep .v-dialog {
        max-height: 100%!important;
    }

    .hb-blade-header-card-wrapper {
        background-color: #E0F5F5;
    }
</style>
